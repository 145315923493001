/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = "Consultoría para negocios"
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

  return (
    <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        link={
          canonical
            ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:url`,
            content: "https://www.cienegocios.com",
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:site_name`,
            content: `CIE Consultoría Integral Estructurada`,
          },
          {
            property: `og:image`,
            content: `https://www.cienegocios.com/static/ed959d50dd234f5266f290e5795a917f/ccdb5/consulting.webp`,
          }
        ].concat(meta)}
      >
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://www.cienegocios.com",
              "name": "Consultoría Integral Estructurada.",
              "@id": "https://www.cienegocios.com",
              "telephone": "+52 1 8117434207",
              "email": "contacto@cienegocios.com",
              "image": [
                "https://www.cienegocios.com/static/ed959d50dd234f5266f290e5795a917f/ccdb5/consulting.webp"
              ],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Av. Lomas del Valle #430",
                "addressLocality": "San Pedro Garza García",
                "addressRegion": "NL",
                "postalCode": "66256",
                "addressCountry": "MX"
              }
            }
        `}
        </script>
      </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: "Consultoría profesional para negocios especializada. Somos un grupo de profesionistas y profesionales en diferentes áreas de Negocios, Administración, Finanzas, Legal, Legal-Corporativo, Apertura de Empresas, Impuestos que comprenden entre otros: análisis, determinación, cumplimiento de obligaciones, etc.; Fiscal: análisis de situación de empresas, procesos de cumplimiento de obligaciones y declaraciones, así como la defensa fiscal en todos los rubros; Dictámenes Fiscales SAT, Dictámenes Seguro Social, además de que somos expertos en el manejo de procesos de SATIC / SIROC con el IMSS, para Constructoras, entre otras.",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
